import React from "react"
import { InlineWidget } from "react-calendly"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  Button,
  Span,
  Link,
} from "@/components/index"
import { navigate } from "gatsby"
import useStore from "@/store/index"

export default function Skillassessment() {
  const handleSubmit = e => {
    e.preventDefault()
    navigate("/confirmation")
  }
  const user = useStore(state => state.user)
  const calendlyBase =
    "https://calendly.com/guitar-mastery-intensive/skill-assessment?text_color=7453bd&primary_color=2f1765&hide_event_type_details=1"
  let calendlyLink = user.email
    ? calendlyBase + "&email=" + user.email + "&name=" + user.first_name + " " + user.last_name
    : calendlyBase
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO title="Skill assessment" description="" />
      <Box
        minheight="100%"
        height="100%"
        z
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["16.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection={["column", null, null, "row"]}
          pt={["0", null, null, "6rem"]}
        >
          <Box
            width={["100%", null, null, 6 / 12]}
            textAlign="center"
            mx="auto"
          >
            <Text variant="subhead">Step 3 of 4</Text>
            <Heading level="2">Schedule your free skill assessment</Heading>
          </Box>
        </Width>
        <Width>
          <Button variant="primary" onClick={e => handleSubmit(e)} mt="3.6rem">
            Skip this step
          </Button>
          <InlineWidget
            url={calendlyLink}
            styles={{
              margin: "0px",
              height: "1000px",
            }}
          />
        </Width>
        <Width pb={["0", null, null, "13.9rem"]}>
          <Text level={2}>
            <Span fontWeight="800">Have questions?</Span>{" "}
            <Link
              to="mailto:contact@learnwithsonora.com"
              as="a"
              color="neutralBlue"
            >
              Contact us
            </Link>
            .
          </Text>
        </Width>
      </Box>
    </Layout>
  )
}
